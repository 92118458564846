.slider-container {
	display: flex;
	align-items: center;
	justify-content: center;
	/* height: 100vh; */
	overflow-x: hidden;
}

.card-slider {
	display: flex;
	overflow-x: hidden;
	overflow: hidden;
}

.card {
	flex: 0 0 100%;
	transition: transform 0.3s ease;
	opacity: 0;
	visibility: hidden;
	position: absolute;
	left: 0;
}

.card.active {
	opacity: 1;
	visibility: visible;
	transform: translateX(0);
	width: 100vw;
	background: transparent;
	transition: 0.3s;
}

.card.prev {
	transform: translateX(-100%);
}

.card.next {
	transform: translateX(100%);
}
