.about {
	padding: 120px 40px 0 40px;
	max-width: 1200px;
	margin: 0 auto;
}

.main__title {
	text-align: center;
	color: #fff;
	margin-bottom: 30px;
}

.about__smart {
	width: 100%;
	height: 260px;
	border-radius: 20px;
	display: flex;
	align-items: center;
	gap: 20px;
	margin-bottom: 30px;
}

.about__smart-left {
	padding: 30px 50px;
	width: 450px;
	border-right: 3px solid #fff;
	display: flex;
	justify-content: center;
	align-items: center;
}

.about__smart-right {
	padding: 30px 50px;
}

.about-logo {
	width: 350px;
}

.about__text {
	font-size: 20px;
	margin-bottom: 20px;
	background-color: #e2e2e2;
	border-radius: 20px;
	padding: 30px 40px;
	text-align: center;
}

.about__text p {
	margin-bottom: 0;
}

.about__text-second {
	display: flex;
	gap: 10px;
	font-size: 24px;
	margin-bottom: 20px;
}

.text-second-left {
	width: 50%;
	text-align: center;
	border-radius: 20px;
	padding: 50px 40px;
	background-color: #bf1d69;
	color: #fff;
}

.text-second-right {
	width: 50%;
	text-align: center;
	padding: 50px 40px;
	border-radius: 20px;
	background-color: #e2e2e2;
}

.right-logos {
}

@media screen and (max-width: 1000px) {
	.about__smart {
		flex-direction: column;
		height: 100%;
	}

	.about__smart-left {
		width: 100%;
		border-right: none;
		border-bottom: 3px solid #fff;
		border-radius: 0;
	}

	.about__smart-right {
		width: 100%;
	}
}

@media screen and (max-width: 600px) {
	.about {
		padding: 100px 20px 0 20px;
	}
	.about-logo {
		width: 240px;
	}

	.about__smart {
		width: 350px;
		margin: 0 auto 20px auto;
	}
	.about__smart-left {
		padding: 15px 25px;
		display: flex;
		justify-content: center;
		border-right: none !important;
	}
	.about__smart-right {
		padding: 15px 25px;
	}

	.about__smart.founded {
		height: 270px !important;
		width: 350px;
		margin: 0 auto 20px auto;
	}

	.about__text-second {
		flex-wrap: wrap;
		align-items: center;
		justify-content: center;
	}

	.text-second-left,
	.text-second-right {
		width: 350px;
	}
}
