.caption h5,
.caption p {
	color: #fff;
}

.App {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	background: #404040;
}

/* .carousel__image {
	max-width: 100%;
	max-height: 100%;
} */
