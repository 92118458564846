.heading {
	font-size: 48px;
	color: #fff;
	text-align: center;
	letter-spacing: 1px;
	display: block;
	margin: 0 auto 20px auto;
}

.subheading {
	font-size: 26px;
	color: #fff;
	text-align: center;
	letter-spacing: 1px;
	display: block;
	margin: 0 auto 50px auto;
}

.heading__main {
	display: flex;
	justify-content: center;
	gap: 20px;
	margin-bottom: 80px;
	flex-wrap: wrap;
}

.heading__main-form {
	width: 50%;
	max-width: 650px;
}

.form-heading {
	font-size: 26px;
	color: #fff;
	text-align: center;
	letter-spacing: 1px;
	display: block;
	margin: 0 auto 30px auto;
}

.form-subtitle {
	font-size: 18px;
	color: #fff;
	text-align: center;
	letter-spacing: 1px;
	display: block;
	margin: 0 auto;
	/* font-weight: bold; */
}

.heading__form {
	padding: 10px 5px 45px 5px;
	background-color: #5e5e5e;
	border-radius: 20px;
}
.form-group {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 15px;
	margin: 42px 0;
}
.form__input {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	gap: 20px;
}

.form__input input {
	width: 340px;
	padding: 12px 16px;
	border-radius: 20px;
	text-align: center;
	outline: none;
	border: none;
}

.form__input {
	font-size: 20px;
	color: #fff;
}

.label-main {
	width: 210px;
	text-align: center;
	font-size: 16px;
}

.btn-submit {
	padding: 30px 40px;
	margin: 30px auto;
	border-radius: 20px;
	background: #bf1d69;
	border: none;
	font-size: 22px;
	font-weight: 300;
	color: #fff;
	cursor: pointer;
	text-align: center;
	display: flex;
	justify-content: center;
}

.heading__left {
	width: 40%;
	max-width: 600px;
	min-width: 450px;
}

.info__block {
	/* width: 40%;
	max-width: 600px;
	min-width: 450px; */
	background: #fff;
	border-radius: 20px;
	padding: 20px 10px;
}

.info__block-subtitle {
	font-size: 18px;
	color: #000;
	text-align: center;
	letter-spacing: 1px;
	display: block;
	margin: 0 auto;
}

.info__block-list {
	margin-top: 20px;
}

.heading__main-warning {
	/* width: 40%;
	max-width: 600px;
	min-width: 450px; */
}

.warning-heading {
	font-size: 42px;
	color: #fff;
	text-align: center;
	letter-spacing: 1px;
	display: block;
	margin: 10px auto;
	font-weight: medium;
}

.warning-box {
	border-radius: 20px;
	background: #bf1d69;
	color: #fff;
	font-size: 26px;
	text-align: center;
	padding: 30px 50px;
	font-weight: medium;
}

.bottom__text {
	max-width: 1270px;
	margin: 0 auto;
}

.bottom__text-standart {
	padding: 20px 30px;
	background: #5e5e5e;
	border-radius: 20px;
	margin-bottom: 20px;
}

.standart-heading {
	font-size: 26px;
	color: #fff;
	text-align: center;
	letter-spacing: 1px;
	display: block;
	margin: 0 auto 30px auto;
}

.standart-text {
	font-size: 20px;
	color: #fff;
	letter-spacing: 1px;
	display: block;
	margin-bottom: 20px;
}

.bottom__text-warranty {
	padding: 20px 30px 60px 30px;
	background: #5e5e5e;
	border-radius: 20px;
	margin-bottom: 30px;
}

.error {
	color: red;
	font-size: 22px;
	display: block;
	text-align: center;
}

.success {
	color: #07e107;
	font-size: 22px;
	display: block;
	text-align: center;
}

#series.verified {
	color: #07e107;
}

#series.not-verified {
	color: red;
}

@media screen and (max-width: 940px) {
	.heading__main-form {
		width: 90%;
	}

	.form-subtitle {
		/* font-size: 14px; */
	}

	.label-main {
		/* font-size: 12px; */
	}

	.heading__main {
	}
}

@media screen and (max-width: 600px) {
	.form__input input {
		width: 240px;
	}
	.warranty {
		padding-top: 70px;
	}

	.heading {
		font-size: 32px;
	}

	.heading__form {
		padding: 10px 5px 20px 5px;
	}

	.subheading,
	.form-heading,
	.warning-box,
	.standart-heading,
	.details-title {
		font-size: 18px;
	}

	.heading__left {
		min-width: unset;
		max-width: unset;
		width: 90%;
	}
	.info__block-list {
		padding-left: 1rem;
		font-size: 14px;
	}

	.warning-heading {
		font-size: 32px;
	}

	.form__input {
		flex-direction: column;
		gap: 5px;
	}

	.btn-submit,
	.standart-text,
	.details-p,
	.details-warning {
		font-size: 14px;
	}

	.bottom__text-standart,
	.bottom__text-warranty {
		margin: 0 20px 20px 20px;
		padding: 10px 20px;
	}

	.details {
		padding: 10px 20px;
	}
	.details-top {
		flex-wrap: wrap;
		gap: 20px;
	}

	.details-bottom {
		flex-wrap: wrap;
	}

	.details-bottom-item {
		width: 100%;
		margin-bottom: 20px;
	}
}
