.header__main {
	padding: 0px 35px;
	background-color: #000;
	display: flex;
	justify-content: space-between;
	align-items: center;
	position: fixed;
	z-index: 1000;
	top: 0;
	left: 0;
	width: 100%;
	background: #bf1d69;
	color: #fff;
	font-size: 20px;
	font-weight: 300;
	position: sticky;
}

.header__main a {
	color: #fff;
	text-decoration: none;
}

.header__main__logo a {
	color: #fff;
	font-size: 20px;
	font-weight: 600;
	text-decoration: none;
}

.header__main__user {
	display: flex;
	gap: 10px;
	align-items: center;
}

.header__main__user a {
	font-size: 16px;
}

.header__language {
	cursor: pointer;
}

button.btn {
	/* width: 100%; */
	height: 30px;
	border-radius: 5px;
}
a.btn {
	height: 40px;
}
/* .header__main__user a {
} */

.header__main__nav {
	/* max-width: 80%; */
	padding-top: 17px;
}

.header__main__nav__list {
	display: flex;
	list-style: none;
}

.header__main__nav__list li a {
	color: #fff;
	text-decoration: none;
	font-size: 16px;
	font-weight: 400;
	padding: 0 20px;
}

.header__left {
	display: flex;
	align-items: center;
}

.globe {
	/* height: 32px; */
	width: 24px;
	cursor: pointer;
}

.main__logo {
	height: 46px;
	/* width: 32px; */
}

.header__language {
	display: none;
	flex-direction: column;
	width: 40px;
	align-items: center;
	margin: 0 auto 0 auto;
	background-color: #fff;
	color: #5e5e5e;
	padding: 8px;
	border-bottom-left-radius: 17px;
	border-bottom-right-radius: 17px;
	position: absolute;
	bottom: -106px;
	left: -8px;
}

.header__language.active {
	display: flex;
}

.language-item {
	font-size: 16px;
	padding: 3px;
	width: 30px;
	text-align: center;
}

.language-item.active {
	background-color: #bf1d69;
	color: #fff;
}

@media screen and (max-width: 920px) {
	.header__main__nav {
		display: none;
	}

	.header__main__user {
		display: none;
	}

	.header__main {
		padding: 5px 10px;
	}
}

@media screen and (max-width: 660px) {
	.header__main {
		position: fixed;
	}
}
