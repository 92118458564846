.request {
	padding: 120px 40px 100px 40px;
	max-width: 1200px;
	margin: 0 auto;
}

.form-box {
	padding: 30px 70px 30px 30px;
	text-align: center;
	background: #5e5e5e;
	border-radius: 20px;
	display: flex;
	justify-content: space-between;
}

.form-box textarea,
.form-box select {
	padding: 8px 12px;
	outline: none;
	border-radius: 15px;
	border: none;
}

.form-box label {
	font-size: 20px;
	color: #fff;
}

.form-box-left {
	width: 70%;
	border-right: 3px solid #fff;
}

.request form {
	width: 90%;
	display: flex;
	flex-direction: column;
	gap: 10px;
}

.input__group {
	display: flex;
	flex-direction: column;
	gap: 10px;
}

.input__group input {
	padding: 8px 12px;
	outline: none;
	border-radius: 15px;
	border: none;
}

.input__group-row {
	display: flex;
	gap: 20px;
}

@media screen and (max-width: 1000px) {
	.form-box-left {
		border: none;
		width: 100%;
		margin: 0 auto;
	}

	.form-box {
		flex-direction: column;
		padding: 20px 15px;
	}

	.right-logos {
		display: none !important;
	}

	.request form {
		margin: 0 auto;
	}
}

@media screen and (max-width: 600px) {
	.input__group-row {
		flex-wrap: wrap;
	}

	.input__group {
		width: 100% !important;
	}

	.about__text {
		font-size: 20px !important;
	}

	.form-box label {
		font-size: 18px;
	}

	.request {
		padding: 90px 20px 20px 20px;
	}
}
