.service {
	padding: 0;
	margin-top: 630px;
}

.banner {
	height: 700px;
	margin-bottom: 45px;
}

.service-heading {
	text-align: center;
	color: #fff;
	margin-bottom: 60px;
}

.service__container {
	display: flex;
	justify-content: center;
	gap: 20px;
	margin-bottom: 70px;
	flex-wrap: wrap;
}

.service__container-left {
	width: 500px;
	height: 400px;
	background: #5e5e5e;
	border-radius: 30px;
	padding: 30px;
	display: flex;
	flex-direction: column;
	align-items: center;
}

.left-title {
	font-size: 32px;
	color: #fff;
	text-align: center;
	letter-spacing: 1px;
}

.left-bottom {
	display: flex;
	align-items: center;
	gap: 20px;
	margin-top: 60px;
}

.service-left-bottom-text,
.service-right-top-text {
	display: flex;
	flex-direction: column;
	align-items: center;
	font-size: 26px;
	color: #fff;
	font-weight: 300;
}

.service-left-bottom-title {
	line-height: 20px;
}

.service-left-bottom-link {
	color: #fff;
	text-decoration: underline;
}

.service-left-bottom-link:hover {
	color: #fff;
	text-decoration: underline;
}

.service__container-right {
}

.service-right-top {
	width: 500px;
	height: 190px;
	background: #bf1d69;
	border-radius: 30px;
	padding: 30px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.service-right-top-title {
	font-size: 32px;
	color: #fff;
	text-align: center;
	letter-spacing: 1px;
}

.service-right-top-link {
	color: #fff;
	text-decoration: none;
}
.service-right-top-link:hover {
	color: #fff;
	text-decoration: underline;
}

.service-right-bottom {
	width: 500px;
	height: 190px;
	background: #5e5e5e;
	border-radius: 30px;
	padding: 30px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	margin-top: 20px;
}

.service-right-bottom-title {
	font-size: 32px;
	color: #fff;
	text-align: center;
	letter-spacing: 1px;
	text-decoration: none;
}

.service-right-bottom-title:hover {
	color: #fff;
	text-decoration: underline;
}

.partners__heading {
	text-align: center;
	color: #fff;
	margin-bottom: 60px;
	font-size: 2.5rem;
}

.partners__container {
	display: flex;
	justify-content: center;
	gap: 30px;
	margin-bottom: 70px;
	flex-wrap: wrap;
}

.partners__container-item {
	display: flex;
	justify-content: center;
	align-items: center;
}

.partners__image {
	width: 270px;
	height: 120px;
}

@media screen and (max-width: 600px) {
	.service__container-left {
		width: 350px;
		height: 220px;
	}

	.left-bottom {
		margin-top: 25px;
	}

	.left-bottom img {
		width: 80px;
	}

	.left-title {
		font-size: 22px;
	}

	.service-left-bottom-text,
	.service-right-top-text {
		font-size: 22px;
	}

	.service-right-top,
	.service-right-bottom {
		width: 350px;
	}

	.service-right-top-title,
	.service-right-bottom-title {
		font-size: 24px;
	}

	.service__container-right {
		display: flex;
		flex-direction: column;
		align-items: center;
	}
}
